import { Injectable } from '@angular/core';
import Ping from 'ping.js' 
import { DEFAULT_PING_URL, REQUEST_TIMEOUT, RETRY_COUNT, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from '../constants/http-requests.const';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, retry, timeoutWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService {
  constructor() {
  }

  checkInternetConnection(): Observable<{isAlive:boolean}> {
    return from(this.ping()).pipe(
      retry(RETRY_COUNT),
      map((res: boolean) => ({ isAlive: res })),
      catchError((error) => {
        return of({ isAlive: true })
      })
    )
  }

  ping() {
    const ping2 = new Ping();
    return  new Promise((resolve, reject) => {
      ping2.ping(DEFAULT_PING_URL, (err, data) => {
        if(err) {
          resolve(false);
        } else {
          resolve(true)
        }
      })
    })
  }

}

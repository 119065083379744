import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { EmployeeService } from '../../services/employee.service';
import { AreaService } from 'src/app/services/area.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { ToExcelService } from 'src/app/services/to-excel.service';
import { ErrorService } from '../../services/error.service';
import { BusinessService } from 'src/app/services/business.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html'
})
export class EmployeesComponent implements OnInit {

  employees = [];
  @Input() idQuestionnaireApplication;
  showSpinner = false;
  newEmployeeForm: FormGroup;
  aux_user: any;
  @ViewChild('modalEmployeeNew') public modalEmployeeNew: ModalDirective;
  @ViewChild('modalEmployeeDelete') public modalEmployeeDelete: ModalDirective;
  @ViewChild('modalEmployeeUpload') public modalEmployeeUpload: ModalDirective;
  @ViewChild('modalEmployeeUnselect') public modalEmployeeUnselect: ModalDirective;
  @ViewChild('modalEmployeeSelect') public modalEmployeeSelect: ModalDirective;
  @ViewChild('csvReader') csvReader: any;  
  upload_employees = [];  
  deleteEmployee = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  loadingUpload = false;
  loadingDownload = false;
  loadingSelect = false;
  areas = [];
  business = [];
  accesscodes = [];
  answer_statuses = [];
  arrayBuffer: any;
  questionnaireApplication: any;
  employees_upload = [];
  employees_download = [];
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  search_text = "";
  search_status;
  filter_number = 1;
  pageAux;
  maxPage;
  uploadErrors = [];

  constructor(private employeeService: EmployeeService, private router: Router, private notifierService: NotifierService,
    private areaService: AreaService, private questionarioService: QuestionnaireService, private authService: AuthService,
    private toExcelService: ToExcelService, private errService: ErrorService, private _businessService: BusinessService) {
    this.newEmployeeForm = new FormGroup({
      application_email: new FormControl(''),
      email: new FormControl(''),
      user: new FormControl(''),
      access: new FormControl(''),
      access_code: new FormControl(''),
      cellphone_prefix: new FormControl(''),
      cellphone: new FormControl(''),
      name: new FormControl(''),
      selected_for_application: new FormControl(false),
      age: new FormControl(''),
      occupation: new FormControl(''),
      area_id: new FormControl(''),
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getQuestionnaireApplication();
    this.getAnswerStatuses();
    let searchObject = JSON.parse(sessionStorage.getItem('search_employee'));
    if (searchObject !== null) {
      setTimeout(() => {
        if (parseInt(searchObject.idQuestionnaireApplication) !== this.idQuestionnaireApplication) {
          sessionStorage.removeItem('search_employee');
          this.getEmployees(1);
        }
        this.offset = parseInt(searchObject.offset);
        this.limit = parseInt(searchObject.limit);
        this.limitUp = parseInt(searchObject.lmt_up);
        this.limitDown = parseInt(searchObject.lmt_down);
        this.search_text = searchObject.search_text;
        this.search_status = parseInt(searchObject.search_status);
        this.filter_number = parseInt(searchObject.filter_number);
        sessionStorage.removeItem('search_employee');
        this.makeSearch(2);
      }, 600);
    } else {
      this.getEmployees(1);
    }
  }

  async getAnswerStatuses() {
    const res = await this.employeeService.getAnswerStatuses();
    if (res.success) {
      this.answer_statuses = res.data.answer_statuses;
    }
  }

  async getEmployees(type) {
    let res;
    if (type === 4) {
      this.loadingDownload = true;
      res = await this.employeeService.getByQuestionnaireApplication(this.idQuestionnaireApplication, null, null);
      if (res.success) {
        this.employees_download = res.data.employees;
        this.exportEmployees();
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    } else {
      this.employees = [];
      this.showSpinner = true;
      res = await this.employeeService.getByQuestionnaireApplication(this.idQuestionnaireApplication, this.limit, this.offset);
      this.showSpinner = false;
      if (res.success) {
        this.employees = res.data.employees;
        this.count = res.data.count;
        this.maxPage = `max. ${Math.ceil(this.count / this.limit)} págs.`;
        if (type === 1) {
          this.limitUp = this.limit >= this.count ? this.count : this.limit;
        }
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  async generateAccessCodes(){
    const res = await this.questionarioService.generateAccessCodes(this.idQuestionnaireApplication)
    if(res.success){
      this.notifierService.notify('default','Se generaron codigos de acceso para todos los usuarios');
    }else{
      this.notifierService.notify('Error','Ocurrio un problema, intentelo de nuevo');
    }
  }

  async getEmployeesByEmail(type) {
    let res;
    if (type === 4) {
      this.loadingDownload = true;
      res = await this.employeeService.getByApplicationEmail(this.idQuestionnaireApplication, this.search_text, null, null);
      if (res.success) {
        this.employees_download = res.data.employees;
        this.exportEmployees();
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    } else {
      this.employees = [];
      this.showSpinner = true;
      res = await this.employeeService.getByApplicationEmail(this.idQuestionnaireApplication, this.search_text, this.limit, this.offset);
      this.showSpinner = false;
      if (res.success) {
        this.employees = res.data.employees;
        this.count = res.data.count;
        this.maxPage = `max. ${Math.ceil(this.count / this.limit)} págs.`;
        if (type === 1) {
          this.limitUp = this.limit >= this.count ? this.count : this.limit;
        }
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  async getEmployeesByName(type) {
    let res;
    if (type === 4) {
      this.loadingDownload = true;
      res = await this.employeeService.getByName(this.idQuestionnaireApplication, this.search_text, null, null);
      if (res.success) {
        this.employees_download = res.data.employees;
        this.exportEmployees();
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    } else {
      this.employees = [];
      this.showSpinner = true;
      res = await this.employeeService.getByName(this.idQuestionnaireApplication, this.search_text, this.limit, this.offset);
      this.showSpinner = false;
      if (res.success) {
        this.employees = res.data.employees;
        this.count = res.data.count;
        this.maxPage = `max. ${Math.ceil(this.count / this.limit)} págs.`;
        if (type === 1) {
          this.limitUp = this.limit >= this.count ? this.count : this.limit;
        }
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  async getEmployeesByAnswerStatus(type) {
    let res;
    if (type === 4) {
      this.loadingDownload = true;
      res = await this.employeeService.getByAnswerStatus(this.idQuestionnaireApplication, this.search_status, null, null);
      if (res.success) {
        this.employees_download = res.data.employees;
        this.exportEmployees();
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    } else {
      this.employees = [];
      this.showSpinner = true;
      this.offset = type === 1 ? 0 : this.offset;
      this.limitDown = type === 1 ? 1 : this.limitDown;
      res = await this.employeeService.getByAnswerStatus(this.idQuestionnaireApplication, this.search_status, this.limit, this.offset);
      this.showSpinner = false;
      if (res.success) {
        this.employees = res.data.employees;
        this.count = res.data.count;
        this.maxPage = `max. ${Math.ceil(this.count / this.limit)} págs.`;
        if (type === 1) {
          this.limitUp = this.limit >= this.count ? this.count : this.limit;
        }
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  async selectAllEmployees(type) {
    this.loadingSelect = true;
    if (type === 1) {
      const res = await this.questionarioService.setSelected(this.idQuestionnaireApplication);
      this.loadingSelect = false;
      if (res.success) {
        this.notifierService.notify('success', 'Colaboradores seleccionados');
      } else {
        this.notifierService.notify('error', 'Algo salió mal');
      }
    } else {
      const res = await this.questionarioService.setUnselected(this.idQuestionnaireApplication);
      this.loadingSelect = false;
      if (res.success) {
        this.notifierService.notify('success', 'Colaboradores desmarcados');
      } else {
        this.notifierService.notify('error', 'Algo salió mal');
      }
    }
  }

  async getQuestionnaireApplication() {
    const res = await this.questionarioService.getById(this.idQuestionnaireApplication);
    if (res.success) {
      this.questionnaireApplication = res.data.questionnaire_application;
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getAreas() {
    const res = await this.areaService.getByClientCatalog(this.questionnaireApplication.client_id);
    if (res.success) {
      this.areas = res.data.areas;
    }
  }

  async getBusiness(){
    const res = await this._businessService.getByClientCatalog(this.questionnaireApplication.client_id);
    if (res.success) {
      this.business = res.data.businesses;
    }
  }

  employeeDetails(id) {
    sessionStorage.setItem('search_employee', JSON.stringify({
      offset: this.offset,
      limit: this.limit,
      lmt_up: this.limitUp,
      lmt_down: this.limitDown,
      search_text: this.search_text,
      search_status: this.search_status,
      filter_number: this.filter_number,
      idQuestionnaireApplication: this.idQuestionnaireApplication
    }));
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/cuestionario/colaborador/', id]);
    } else {
      this.router.navigate(['/client/cuestionarios/colaborador/', id]);
    }
  }

  goToPage() {
    let destiny = parseInt(this.pageAux);
    if (destiny !== NaN && destiny <= Math.ceil(this.count/this.limit)) {
      this.offset = this.limit * (destiny - 1);
      this.makeSearch(2);
      this.limitUp = (this.limit * destiny) <= this.count ? (this.limit * destiny) : this.count;
      this.limitDown = this.offset + 1;
      window.scroll(0, 0);
      this.pageAux = '';
    } else {
      window.scroll(0, 0);
      this.pageAux = '';
    }
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.makeSearch(1);
  }

  makeSearchText() {
    this.offset = 0;
    this.limitDown = 1;
    if (this.search_text !== "") {
      if (this.filter_number === 2) {
        this.getEmployeesByEmail(1);
      } else if (this.filter_number === 3) {
        this.getEmployeesByName(1);
      }
    } else if (this.filter_number === 2 || this.filter_number === 3) {
      this.getEmployees(1);
    }
  }

  clearTextInput() {
    this.search_text = "";
    this.search_status = undefined;
    if (this.filter_number === 1) {
      this.getEmployees(1);
    }
  }

  nextPage() {
    this.offset += this.limit;
    this.makeSearch(2);
    this.limitDown = this.offset + 1;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.makeSearch(2);
    this.limitDown = this.offset + 1;
    this.limitUp = this.offset + this.limit;
  }

  makeSearch(type) {
    switch (this.filter_number) {
      case 1:
        this.getEmployees(type);
        break;
      case 2:
        this.getEmployeesByEmail(type);
        break;
      case 3:
        this.getEmployeesByName(type);
        break;
      case 4:
        if (this.search_status !== undefined) {
          this.getEmployeesByAnswerStatus(type);
        } else {
          this.getEmployees(1);
        }
        break;
    };
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  openDeleteEmployeeModal(id, name) {
    this.deleteEmployee.id = id;
    this.deleteEmployee.name = `${name}`;
    this.modalEmployeeDelete.show();
  }

  showUploadModal() {
    this.uploadErrors = [];
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.business.length === 0) {
      this.getBusiness();
    }
    this.modalEmployeeUpload.show();
  }

  uploadEmployees($event: any) {
    const file = $event.target.files[0];

    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      this.upload_employees = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    }

    fileReader.readAsArrayBuffer(file);
  }

  async removeEmployee() {
    this.loadingDelete = true;
    const res = await this.employeeService.deleteById(this.deleteEmployee.id);
    if (res.success) {
      this.offset = 0;
      this.getEmployees(1);
      this.notifierService.notify('success', 'Colaborador eliminado');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalEmployeeDelete.hide();
    this.loadingDelete = false;
  }

  showNewEmployeeModal() {
    this.clearValues();
    this.getAreas();
    this.modalEmployeeNew.show();
  }

  clearValues() {
    this.newEmployeeForm.controls.application_email.setValue('');
    this.newEmployeeForm.controls.email.setValue('');
    this.newEmployeeForm.controls.user.setValue('');
    this.newEmployeeForm.controls.access.setValue('');
    this.newEmployeeForm.controls.access_code.setValue('');
    this.newEmployeeForm.controls.cellphone_prefix.setValue('');
    this.newEmployeeForm.controls.cellphone.setValue('');
    this.newEmployeeForm.controls.name.setValue('');
    this.newEmployeeForm.controls.selected_for_application.setValue(0);
    this.newEmployeeForm.controls.age.setValue('');
    this.newEmployeeForm.controls.occupation.setValue('');
    this.newEmployeeForm.controls.area_id.setValue('');
  }

  async addUploadEmployee() {
    this.loadingUpload = true;
    if (this.upload_employees[0].hasOwnProperty('area')) {
      for (let i in this.upload_employees) {
        let aid = this.getAreaId(String(this.upload_employees[i]['area']));
        if (aid !== 0) {
          this.upload_employees[i]['area_id'] = aid;
        }
        delete this.upload_employees[i].area;
      } 
    }
    if (this.upload_employees[0].hasOwnProperty('business')) {
      for (let i in this.upload_employees) {
        let bid = this.getBusinessId(String(this.upload_employees[i]['business']));
        if (bid !== 0) {
          this.upload_employees[i]['business_id'] = bid;
        }
        delete this.upload_employees[i].business;
      } 
    }
    if(this.upload_employees[0].hasOwnProperty('accesscode')){
      for (let i in this.upload_employees) {
        if(this.upload_employees[i]['accesscode'] !== '' && this.upload_employees[i]['accesscode'] !== null && this.upload_employees[i]['accesscode'] !== undefined){
          this.upload_employees[i]['access_code'] = this.upload_employees[i]['accesscode'];
        }
        delete this.upload_employees[i].accesscode;
      }
    }
    this.uploadErrors = [];
    const data = {
      questionnaire_application_id: this.idQuestionnaireApplication,
      employees: this.upload_employees
    }
    const res = await this.employeeService.upload(data);
    if (res.success) {
      this.offset = 0;
      this.getEmployees(1);
      this.notifierService.notify('success', 'Colaboradores añadidos');
      this.modalEmployeeUpload.hide();
    } else {
      const err = res.error.code;
      if(typeof res.error.details !== 'undefined'){
        const details = res.error.details;
        if (typeof details.employees !== 'undefined') {
          if (typeof details.employees.value !== 'undefined' && typeof details.employees.msg !== 'undefined') {
            const employees = details.employees;
            this.uploadErrors.push({
              email: employees.value.application_email,
              message: employees.msg
            });
          } else {
            this.uploadErrors.push({
              email: 'ND',
              message: res.error
            });
          }
        } else {
          this.uploadErrors.push({
            email: 'ND',
            message: res.error
          });
        }
      } else {
        this.uploadErrors.push({
          email: 'ND',
          message: res.error
        });
      }
      
      switch (err) {
        case 312:
          this.notifierService.notify('error', 'Error en la base de datos');
          break;
        case 305:
          this.notifierService.notify('error', 'Error al subir el archivo');
          break;
        default:
          this.notifierService.notify('error', this.errService.getError(res.error.code));
      }  
    }
    this.loadingUpload = false;
  }

  private getAreaId(name: string) {
    let id = 0;
    this.areas.forEach( (area) => {
      if (this.filterChars(area.name.toUpperCase()) === this.filterChars(name.toUpperCase())) {
        id = area.id_area;
      }
    });
    return id;
  }

  private getBusinessId(name: string){
    let id = 0;
    this.business.forEach( (business) => {
      if (this.filterChars(business.name.toUpperCase()) === this.filterChars(name.toUpperCase())) {
        id = business.id_business;
      }
    });
    return id;
  }

  private filterChars(word: string){
    word = word.replace('Á', 'A');
    word = word.replace('É', 'E');
    word = word.replace('Í', 'I');
    word = word.replace('Ó', 'O');
    word = word.replace('Ú', 'U');
    word = word.replace(/(^[,\s]+)|([,\s]+$)/g, '');
    word = word.replace(/(^[.\s]+)|([.\s]+$)/g, '');
    word = word.trim()
    return word;
  }

  async addEmployee() {
    this.loadingNew = true;

    const data = this.getPostData();
    
    const res = await this.employeeService.post(data);
    if (res.success) {
      
      this.offset = 0;
      this.getEmployees(1);
      this.notifierService.notify('success', 'Colaborador añadido');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalEmployeeNew.hide();
    this.loadingNew = false;
  }

  async exportEmployees() {

    let array_data = [];

    const res = await this.questionarioService.getSocioDemographics(this.idQuestionnaireApplication);
    let qSD;

    if (res.success) {
      qSD = res.data.application_sociodemographic;
    }

    for (let employee of this.employees_download) {

      let data = {};

      data['Email'] = employee.application_email;
      data['Nombre'] = employee.name;
      data['Area'] = employee.area;
      data['Seleccionado_para_Cuestionario'] = employee.selected_for_application === 1 ? 'Si' : 'No';
      data['Estado_de_Respuesta'] = employee.answer_status
      data['Fecha_de_Envio'] = employee.questionnaire_sent_at;
      data['Fecha_de_Respuesta'] = employee.questionnaire_answered_at;
      data['#_de_Envios'] = employee.number_of_sents;
      if (qSD.email === 1) {
        data['Email_Extra'] = employee.email;
      }
      if (qSD.cellphone === 1) {
        data['Lada'] = employee.cellphone_prefix;
        data['Telefono'] = employee.cellphone;
      }
      if (qSD.gender === 1) {
        data['Genero'] = employee.gender;
      }
      if (qSD.age_segmented === 1) {
        data['Rango_de_Edad'] = employee.age_range;
      }
      if (qSD.age === 1) {
        data['Edad'] = employee.age;
      }
      if (qSD.education_level === 1) {
        data['Nivel_de_Estudios'] = employee.education_level;
      }
      if (qSD.civil_status === 1) {
        data['Estado_Civil'] = employee.civil_status;
      }
      if (qSD.occupation === 1) {
        data['Ocupacion'] = employee.occupation;
      }
      if (qSD.position_type === 1) {
        data['Puesto'] = employee.position_type;
      }
      if (qSD.recruitment_type === 1) {
        data['Contratacion'] = employee.recruitment_type;
      }
      if (qSD.employee_type === 1) {
        data['Tipo_de_Colaborador'] = employee.employee_type;
      }
      if (qSD.working_hours_type === 1) {
        data['Tipo de jornada de trabajo'] = employee.working_hours_type;
      }
      if (qSD.working_hours_rotation === 1) {
        data['Realiza rotación de turnos'] = employee.working_hours_rotation;
      }
      if (qSD.work_experience === 1) {
        data['Experiencia'] = employee.work_experience;
      }
      if (qSD.current_position_time === 1) {
        data['Tiempo_en_Puesto_Actual'] = employee.current_position_time;
      }

      array_data.push(data);
    }

    this.toExcelService.exportAsExcelFile(array_data, `Colaboradores-${Date.now()}`);
    this.loadingDownload = false;
  }

  getPostData() {
    const data = {
      questionnaire_application_id: this.idQuestionnaireApplication,
      answer_status_id: 1,
      selected_for_application: this.newEmployeeForm.controls['selected_for_application'].value,
      application_email: this.newEmployeeForm.controls['application_email'].value,
    };

    if (this.newEmployeeForm.controls['email'].value !== '') {
      data['email'] = this.newEmployeeForm.controls['email'].value;
    }

    if (this.newEmployeeForm.controls['user'].value !== '') {
      data['user'] = this.newEmployeeForm.controls['user'].value;
    }

    if (this.newEmployeeForm.controls['access'].value !== '') {
      data['access'] = this.newEmployeeForm.controls['access'].value;
    }

    if (this.newEmployeeForm.controls['access_code'].value !== '') {
      data['access_code'] = this.newEmployeeForm.controls['access_code'].value;
    }

    if (this.newEmployeeForm.controls['cellphone_prefix'].value !== '') {
      data['cellphone_prefix'] = this.newEmployeeForm.controls['cellphone_prefix'].value;
    }

    if (this.newEmployeeForm.controls['cellphone'].value !== '') {
      data['cellphone'] = this.newEmployeeForm.controls['cellphone'].value;
    }

    if (this.newEmployeeForm.controls['name'].value !== '') {
      data['name'] = this.newEmployeeForm.controls['name'].value;
    }

    if (this.newEmployeeForm.controls['age'].value !== '') {
      data['age'] = this.newEmployeeForm.controls['age'].value;
    }

    if (this.newEmployeeForm.controls['occupation'].value !== '') {
      data['occupation'] = this.newEmployeeForm.controls['occupation'].value;
    }

    if (this.newEmployeeForm.controls['area_id'].value !== '') {
      data['area_id'] = parseInt(this.newEmployeeForm.controls['area_id'].value);
    }

    return data;
  }

}

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AnswerService } from 'src/app/services/answer.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { DEFAULT_WINDOW_REFRESH_ALERT, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE } from 'src/app/constants/alert-messages.const';
import { OnlineStatusService } from 'src/app/services/online-status.service';

@Component({
  selector: 'app-q1',
  templateUrl: './q1.component.html',
  styles: ['./q1.component.css']
})
export class Q1Component implements OnInit {

  q1Form: FormGroup;
  showS1 = true;
  showS2 = false;
  showS3 = false;
  showS4 = false;
  showIntroNextQuestionnaire = false;
  mobile = false;
  showFinal = false;
  loadingSend = false;
  loadingValid = false;
  questionnaireId: number;
  height = '20px';
  progress_value = 0;
  ie = false;
  philips = false;
  konfio = false;
  v2 = false;
  colors: any;
  end_msg = `<h4>Al terminar la aplicación del cuestionario le comunicaremos a tu organización los resultados y planes 
            de acción que apliquen de acuerdo a ello.</h4>`;
  btn_info = {
    show: true,
    text: 'Visita Cuéntame',
    link: 'https://sicuentame.com'
  }
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  } 
  @ViewChild('modalError') public modalError: ModalDirective;

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    $event.returnValue = DEFAULT_WINDOW_REFRESH_ALERT;
  }

  constructor(private answerService: AnswerService, private notifierService: NotifierService, config: NgbProgressbarConfig,
              private router: Router, private onlineStatusService: OnlineStatusService) {
    this.colors = JSON.parse(sessionStorage.getItem('text_colors'));            
    this.q1Form = new FormGroup({
      s1: new FormControl(''),
      s1_1: new FormControl(''),
      s1_2: new FormControl(''),
      s1_3: new FormControl(''),
      s1_4: new FormControl(''),
      s1_5: new FormControl(''),
      s1_6: new FormControl(''),
      s2_1: new FormControl(''),
      s2_2: new FormControl(''),
      s3_1: new FormControl(''),
      s3_2: new FormControl(''),
      s3_3: new FormControl(''),
      s3_4: new FormControl(''),
      s3_5: new FormControl(''),
      s3_6: new FormControl(''),
      s3_7: new FormControl(''),
      s4_1: new FormControl(''),
      s4_2: new FormControl(''),
      s4_3: new FormControl(''),
      s4_4: new FormControl(''),
      s4_5: new FormControl(''),
    });

    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
  }

  ngOnInit() {
    this.validateToken();
    this.checkWindowSize();
    this.isIE();
    this.modalError.config = {ignoreBackdropClick: true};
  }

  checkWindowSize() {
    if (window.screen.width <= 500) { // 768px portrait
      this.mobile = true;
    }
  }

  async validateToken() {
    // this.questionnaireId = 4;
    // this.philips = false;
    // this.konfio = false;
    // this.v2 = false;
    this.loadingValid = true;
    const res = await this.answerService.getData();
    console.log(res)
    if(!res) {
      const onHide = () => {
        this.router.navigate(['/questionnaire/'], { queryParams: { 'load-error': true } });
      }
      this.showErrorModal(RESTART_FROM_ORIGINAL_URL_MESSAGE, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE, onHide)
      return
    }
    if (!res.success) {
      this.showErrorModal(RESTART_FROM_ORIGINAL_URL_MESSAGE, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE);
      this.loadingValid = false;
      this.router.navigate(['/questionnaire/']);
    } else {
      this.answerService.clientDataListener.next(res.data.client);
      this.questionnaireId = res.data.questionnaire_application.questionnaire_id;
      this.philips = res.data.client.name === 'Philips';
      this.konfio = res.data.client.name === 'Konfio';
      this.v2 = !(res.data.client.name === 'Philips' || res.data.client.name === 'Urbvan');
      if (res.data.questionnaire_application.end_message !== null &&
        res.data.questionnaire_application.end_message.length !== 0) {
          this.end_msg = res.data.questionnaire_application.end_message;
      }
      this.btn_info.show = res.data.questionnaire_application.show_link_button === 1;
      if (res.data.questionnaire_application.link_button_message !== null &&
        res.data.questionnaire_application.link_button_message !== 0) {
        this.btn_info.text = res.data.questionnaire_application.link_button_message;
      }
      if (res.data.questionnaire_application.link_button_url !== null &&
        res.data.questionnaire_application.link_button_url !== 0) {
        this.btn_info.link = res.data.questionnaire_application.link_button_url;
      }
      this.loadingValid = false;
    }
  }

  showErrorModal (title: string, code: number, message: string, onHide = null) {
    this.errorModalData = {title: title, code: code, message: message};
    const modal = this.modalError
    modal.config = {ignoreBackdropClick: true};
    if(onHide !== null) {
      modal.onHidden.subscribe(onHide)
    }
    setTimeout(() => {
      modal.show()
    }, 100)
  }

  q1_answered() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(!response.isAlive) {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
        return
      }
      if (this.q1Form.controls['s4_1'].value === '' || this.q1Form.controls['s4_2'].value === '' ||
        this.q1Form.controls['s4_3'].value === '' || this.q1Form.controls['s4_4'].value === '' ||
        this.q1Form.controls['s4_5'].value === '') {
        this.notifierService.notify('error', 'Faltan reactivos por contestar');
      } else {
        this.progress_value = 100;
        this.showS4 = false;
        this.sendAnswers();
      }
    });
  }

  nextSec2() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(!response.isAlive) {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
        return
      }
      if ( this.v2 ) {
        if (this.q1Form.controls['s1_1'].value === '' || this.q1Form.controls['s1_2'].value === '' ||
          this.q1Form.controls['s1_3'].value === '' || this.q1Form.controls['s1_4'].value === '' ||
          this.q1Form.controls['s1_5'].value === '' || this.q1Form.controls['s1_6'].value === '') {
          this.notifierService.notify('error', 'Faltan reactivos por contestar');
        } else {
          if (this.q1Form.controls['s1_1'].value === 0 && this.q1Form.controls['s1_2'].value === 0 &&
            this.q1Form.controls['s1_3'].value === 0 && this.q1Form.controls['s1_4'].value === 0 &&
            this.q1Form.controls['s1_5'].value === 0 && this.q1Form.controls['s1_6'].value === 0) {
            this.progress_value = 100;
            this.showS1 = false;
            this.sendAnswers();
          } else {
            this.progress_value = 25;
            this.showS1 = false;
            this.showS2 = true;
          }
        }
      } else {
        if (this.q1Form.controls['s1'].value === '') {
          this.notifierService.notify('error', 'Faltan reactivos por contestar');
        } else {
          if (this.q1Form.controls['s1'].value === 0) {
            this.progress_value = 100;
            this.showS1 = false;
            this.sendAnswers();
          } else {
            this.progress_value = 25;
            this.showS1 = false;
            this.showS2 = true;
          }
        }
      }
    });
  }

  outCuentameContent() {
    this.isIE();
    if (this.ie) {
      location.assign('https://sicuentame.com/philips/contenido');
    } else {
      window.location.href = 'https://sicuentame.com/philips/contenido';
    }
  }

  outKonfioContent() {
    this.isIE();
    if (this.ie) {
      location.assign('https://sicuentame.com/konfio/contenido');
    } else {
      window.location.href = 'https://sicuentame.com/konfio/contenido';
    }
  }

  nextSec3() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(!response.isAlive) {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
        return
      }
      if (this.q1Form.controls['s2_1'].value === '' || this.q1Form.controls['s2_2'].value === '') {
        this.notifierService.notify('error', 'Faltan reactivos por contestar');
      } else {
        this.progress_value = 50;
        this.showS2 = false;
        this.showS3 = true;
      }
    });
  }

  outGoogleForm() {
    this.isIE();
    if (this.ie) {
      location.assign('https://forms.gle/6sFbLGWLgg5n1RsF9');
    } else {
      window.location.href = 'https://forms.gle/6sFbLGWLgg5n1RsF9';
    }
  }

  isIE() {
    const ua = navigator.userAgent;
    this.ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  }
  
  nextSec4() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(!response.isAlive) {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
        return
      }
      if (this.q1Form.controls['s3_1'].value === '' || this.q1Form.controls['s3_2'].value === '' ||
        this.q1Form.controls['s3_3'].value === '' || this.q1Form.controls['s3_4'].value === '' ||
        this.q1Form.controls['s3_5'].value === '' || this.q1Form.controls['s3_6'].value === '' || 
        this.q1Form.controls['s3_7'].value === '') {
        this.notifierService.notify('error', 'Faltan reactivos por contestar');
      } else {
        this.progress_value = 75;
        this.showS3 = false;
        this.showS4 = true;
      }
    });
  }

  async sendAnswers() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(response.isAlive) {
        this.finishQuestionnaire();
      } else {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });
  }

  finishQuestionnaire(){
    this.loadingSend = true;
    const data = {
      needs_attention: this.getsNeedsAttention(),
      answers: this.getJSONAns()
    }

    if (this.questionnaireId === 4 || this.questionnaireId === 5) {
      this.showIntroNextQuestionnaire = true
      this.answerService.setAnswerDataQ1(data);
      console.log('this.questionnaireId', this.questionnaireId);
      setTimeout(() => {
        if (this.questionnaireId === 4) {
          this.router.navigate(['questionnaire/q2']);
        } else {
          this.router.navigate(['questionnaire/q3']);
        }
      }, 5000);
      return
    }
    
    const request$ = this.answerService.sendAnswers(data);

    request$.subscribe( response => {
      this.loadingSend = false;
      this.showFinal = true;
  
      if (response.success) {
        this.notifierService.notify('success', 'Respuestas envíadas');
      } else {
        this.errorModalData = {...response.error, title: response.error.code === TIMEOUT_ERROR_CODE ? TIMEOUT_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });
  }

  getJSONAns() {
    let data;

    if ( this.v2 ) {
      data = {
        s1_1: this.q1Form.controls['s1_1'].value,
        s1_2: this.q1Form.controls['s1_2'].value,
        s1_3: this.q1Form.controls['s1_3'].value,
        s1_4: this.q1Form.controls['s1_4'].value,
        s1_5: this.q1Form.controls['s1_5'].value,
        s1_6: this.q1Form.controls['s1_6'].value,
        s2_1: this.q1Form.controls['s2_1'].value === '' ? 0 : this.q1Form.controls['s2_1'].value,
        s2_2: this.q1Form.controls['s2_2'].value === '' ? 0 : this.q1Form.controls['s2_2'].value,
        s3_1: this.q1Form.controls['s3_1'].value === '' ? 0 : this.q1Form.controls['s3_1'].value,
        s3_2: this.q1Form.controls['s3_2'].value === '' ? 0 : this.q1Form.controls['s3_2'].value,
        s3_3: this.q1Form.controls['s3_3'].value === '' ? 0 : this.q1Form.controls['s3_3'].value,
        s3_4: this.q1Form.controls['s3_4'].value === '' ? 0 : this.q1Form.controls['s3_4'].value,
        s3_5: this.q1Form.controls['s3_5'].value === '' ? 0 : this.q1Form.controls['s3_5'].value,
        s3_6: this.q1Form.controls['s3_6'].value === '' ? 0 : this.q1Form.controls['s3_6'].value,
        s3_7: this.q1Form.controls['s3_7'].value === '' ? 0 : this.q1Form.controls['s3_7'].value,
        s4_1: this.q1Form.controls['s4_1'].value === '' ? 0 : this.q1Form.controls['s4_1'].value,
        s4_2: this.q1Form.controls['s4_2'].value === '' ? 0 : this.q1Form.controls['s4_2'].value,
        s4_3: this.q1Form.controls['s4_3'].value === '' ? 0 : this.q1Form.controls['s4_3'].value,
        s4_4: this.q1Form.controls['s4_4'].value === '' ? 0 : this.q1Form.controls['s4_4'].value,
        s4_5: this.q1Form.controls['s4_5'].value === '' ? 0 : this.q1Form.controls['s4_5'].value
      }
    } else {
      data = {
        s1: this.q1Form.controls['s1'].value,
        s2_1: this.q1Form.controls['s2_1'].value === '' ? 0 : this.q1Form.controls['s2_1'].value,
        s2_2: this.q1Form.controls['s2_2'].value === '' ? 0 : this.q1Form.controls['s2_2'].value,
        s3_1: this.q1Form.controls['s3_1'].value === '' ? 0 : this.q1Form.controls['s3_1'].value,
        s3_2: this.q1Form.controls['s3_2'].value === '' ? 0 : this.q1Form.controls['s3_2'].value,
        s3_3: this.q1Form.controls['s3_3'].value === '' ? 0 : this.q1Form.controls['s3_3'].value,
        s3_4: this.q1Form.controls['s3_4'].value === '' ? 0 : this.q1Form.controls['s3_4'].value,
        s3_5: this.q1Form.controls['s3_5'].value === '' ? 0 : this.q1Form.controls['s3_5'].value,
        s3_6: this.q1Form.controls['s3_6'].value === '' ? 0 : this.q1Form.controls['s3_6'].value,
        s3_7: this.q1Form.controls['s3_7'].value === '' ? 0 : this.q1Form.controls['s3_7'].value,
        s4_1: this.q1Form.controls['s4_1'].value === '' ? 0 : this.q1Form.controls['s4_1'].value,
        s4_2: this.q1Form.controls['s4_2'].value === '' ? 0 : this.q1Form.controls['s4_2'].value,
        s4_3: this.q1Form.controls['s4_3'].value === '' ? 0 : this.q1Form.controls['s4_3'].value,
        s4_4: this.q1Form.controls['s4_4'].value === '' ? 0 : this.q1Form.controls['s4_4'].value,
        s4_5: this.q1Form.controls['s4_5'].value === '' ? 0 : this.q1Form.controls['s4_5'].value
      }
    }

    return JSON.stringify(data);
  }

  outCuentame(link) {
    window.location.href = link;
  }

  getsNeedsAttention() {
    let rs2 = 0;
    let rs3 = 0;
    let rs4 = 0;

    if ( this.q1Form.controls['s2_1'].value !== '' ) {
      rs2 += this.q1Form.controls['s2_1'].value;
    }
    if ( this.q1Form.controls['s2_2'].value !== '' ) {
      rs2 += this.q1Form.controls['s2_2'].value;
    }

    /////////////////////////
    if ( this.q1Form.controls['s3_1'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_1'].value;
    }
    if ( this.q1Form.controls['s3_2'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_2'].value;
    }
    if ( this.q1Form.controls['s3_3'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_3'].value;
    }
    if ( this.q1Form.controls['s3_4'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_4'].value;
    }
    if ( this.q1Form.controls['s3_5'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_5'].value;
    }
    if ( this.q1Form.controls['s3_6'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_6'].value;
    }
    if ( this.q1Form.controls['s3_7'].value !== '' ) {
      rs3 += this.q1Form.controls['s3_7'].value;
    }

    ////////////////////////
    if ( this.q1Form.controls['s4_1'].value !== '' ) {
      rs4 += this.q1Form.controls['s4_1'].value;
    }
    if ( this.q1Form.controls['s4_2'].value !== '' ) {
      rs4 += this.q1Form.controls['s4_2'].value;
    }
    if ( this.q1Form.controls['s4_3'].value !== '' ) {
      rs4 += this.q1Form.controls['s4_3'].value;
    }
    if ( this.q1Form.controls['s4_4'].value !== '' ) {
      rs4 += this.q1Form.controls['s4_4'].value;
    }
    if ( this.q1Form.controls['s4_5'].value !== '' ) {
      rs4 += this.q1Form.controls['s4_5'].value;
    }

    return (rs2 >= 1 || rs3 >= 3 || rs4 >= 2) ? 1 : 0;
  }
}

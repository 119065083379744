import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AnswerService } from 'src/app/services/answer.service';
import { NotifierService } from 'angular-notifier';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { DEFAULT_WINDOW_REFRESH_ALERT, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE } from 'src/app/constants/alert-messages.const';

@Component({
  selector: 'app-q3',
  templateUrl: './q3.component.html'
})
export class Q3Component implements OnInit {

  q3Form: FormGroup;
  qStep = 0;
  mobile = false;
  loadingSend = false;
  loadingValid = false;
  sec1 = false;
  sec2 = false;
  height = '20px';
  progress_value = 0;
  colors: any;
  questionnaire_id = 0;
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  } 
  @ViewChild('modalError') public modalError: ModalDirective;

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    $event.returnValue = DEFAULT_WINDOW_REFRESH_ALERT;
  }

  constructor(private answerService: AnswerService, private notifierService: NotifierService, config: NgbProgressbarConfig,
    private router: Router) {
    this.colors = JSON.parse(sessionStorage.getItem('text_colors'));
    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    this.q3Form = new FormGroup({
      q1: new FormControl(''),
      q2: new FormControl(''),
      q3: new FormControl(''),
      q4: new FormControl(''),
      q5: new FormControl(''),
      q6: new FormControl(''),
      q7: new FormControl(''),
      q8: new FormControl(''),
      q9: new FormControl(''),
      q10: new FormControl(''),
      q11: new FormControl(''),
      q12: new FormControl(''),
      q13: new FormControl(''),
      q14: new FormControl(''),
      q15: new FormControl(''),
      q16: new FormControl(''),
      q17: new FormControl(''),
      q18: new FormControl(''),
      q19: new FormControl(''),
      q20: new FormControl(''),
      q21: new FormControl(''),
      q22: new FormControl(''),
      q23: new FormControl(''),
      q24: new FormControl(''),
      q25: new FormControl(''),
      q26: new FormControl(''),
      q27: new FormControl(''),
      q28: new FormControl(''),
      q29: new FormControl(''),
      q30: new FormControl(''),
      q31: new FormControl(''),
      q32: new FormControl(''),
      q33: new FormControl(''),
      q34: new FormControl(''),
      q35: new FormControl(''),
      q36: new FormControl(''),
      q37: new FormControl(''),
      q38: new FormControl(''),
      q39: new FormControl(''),
      q40: new FormControl(''),
      q41: new FormControl(''),
      q42: new FormControl(''),
      q43: new FormControl(''),
      q44: new FormControl(''),
      q45: new FormControl(''),
      q46: new FormControl(''),
      q47: new FormControl(''),
      q48: new FormControl(''),
      q49: new FormControl(''),
      q50: new FormControl(''),
      q51: new FormControl(''),
      q52: new FormControl(''),
      q53: new FormControl(''),
      q54: new FormControl(''),
      q55: new FormControl(''),
      q56: new FormControl(''),
      q57: new FormControl(''),
      q58: new FormControl(''),
      q59: new FormControl(''),
      q60: new FormControl(''),
      q61: new FormControl(''),
      q62: new FormControl(''),
      q63: new FormControl(''),
      q64: new FormControl(''),
      q65: new FormControl(''),
      q66: new FormControl(''),
      q67: new FormControl(''),
      q68: new FormControl(''),
      q69: new FormControl(''),
      q70: new FormControl(''),
      q71: new FormControl(''),
      q72: new FormControl(''),
      q73: new FormControl(''),
    });
  }

  ngOnInit() {
    this.validateToken();
    this.checkWindowSize();
    this.isIE();
    this.modalError.config = {ignoreBackdropClick: true};
  }

  showErrorModal (title: string, code: number, message: string, onHide = null) {
    this.errorModalData = {title: title, code: code, message: message};
    const modal = this.modalError
    modal.config = {ignoreBackdropClick: true};
    if(onHide !== null) {
      modal.onHidden.subscribe(onHide)
    }
    setTimeout(() => {
      modal.show()
    }, 100)
  }

  checkWindowSize() {
    if (window.screen.width <= 500) { // 768px portrait
      this.mobile = true;
    }
  }

  backStep() {
    window.scroll(0,0);
    this.qStep--;
  }

  async validateToken() {
    this.loadingValid = true;
    const res = await this.answerService.getData();
    if(!res) {
      const onHide = () => {
        this.router.navigate(['/questionnaire/'], { queryParams: { 'load-error': true } });
      }
      this.showErrorModal(RESTART_FROM_ORIGINAL_URL_MESSAGE, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE, onHide)
      return
    }
    if (!res.success) {
      this.loadingValid = false;
      this.router.navigate(['/questionnaire/']);
    } else {
      this.answerService.clientDataListener.next(res.data.client);
      this.questionnaire_id = res.data.questionnaire_application.questionnaire_id
      this.loadingValid = false;
    }
  }

  alreadyFilled(start, end) {
    for (let i = start; i <= end; i++) {
      if ((start == 65 && !this.sec1) || (start == 69 && !this.sec2))
        break;
      if (this.q3Form.controls[`q${i}`].value === '')
        return true
    }
    return false;
  }

  nextStep(start, end) {
    if (this.alreadyFilled(start, end)) {
      this.notifierService.notify('error', 'Faltan reactivos por contestar');
    } else {
      this.progress_value += 7.1428;
      console.log(start, end, this.qStep);
      if (this.qStep === 13) {
        this.notifierService.notify('default', 'Enviando respuestas');
        this.sendAnswers();
      } else {
        window.scroll(0, 0);
        this.qStep++;
        console.log(this.qStep)
      }
    }
  }

  isIE() {
    const ua = navigator.userAgent;
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  }

  outCuentame(link) {
    if (this.isIE()) {
      location.assign(link);
    } else {
      window.location.href = link;
    }
  }

  getAnswers() {

    let data = {
      answers: JSON.stringify({ sec1: this.sec1, sec2: this.sec2, ...this.q3Form.getRawValue() }),
      final_risk_score: 0,
      cat_work_ambient_score: 0,
      cat_activity_factors_score: 0,
      cat_work_time_level_score: 0,
      cat_work_relations_score: 0,
      cat_organization_score: 0,
      dom_work_conditions_score: 0,
      dom_work_load_score: 0,
      dom_missing_control_score: 0,
      dom_work_hours_score: 0,
      dom_family_score: 0,
      dom_leadership_score: 0,
      dom_work_relations_score: 0,
      dom_violence_score: 0,
      dom_recognition_score: 0,
      dom_instability_score: 0,
      /* Levels */
      final_risk_level_id: 0,
      cat_work_ambient_level_id: 0,
      cat_activity_factors_level_id: 0,
      cat_work_time_level_id: 0,
      cat_work_relations_level_id: 0,
      cat_organization_level_id: 0,
      dom_work_conditions_level_id: 0,
      dom_work_load_level_id: 0,
      dom_missing_control_level_id: 0,
      dom_work_hours_level_id: 0,
      dom_family_level_id: 0,
      dom_leadership_level_id: 0,
      dom_work_relations_level_id: 0,
      dom_violence_level_id: 0,
      dom_recognition_level_id: 0,
      dom_instability_level_id: 0,
    }

    /* Domains */

    for (let i = 1; i <= 5; i++) {
      data.dom_work_conditions_score += this.q3Form.controls[`q${i}`].value;
    }
    
    for (let i = 6; i <= 16; i++) {
      data.dom_work_load_score += this.q3Form.controls[`q${i}`].value;
    }
    
    if (this.sec1) {
      for (let i = 65; i <= 68; i++) {
        data.dom_work_load_score += this.q3Form.controls[`q${i}`].value;
      }
    }

    data.dom_missing_control_score += this.q3Form.controls['q35'].value + this.q3Form.controls['q36'].value;

    for (let i = 23; i <= 30; i++) {
      data.dom_missing_control_score += this.q3Form.controls[`q${i}`].value;
    }

    data.dom_work_hours_score += this.q3Form.controls['q17'].value + this.q3Form.controls['q18'].value;

    data.dom_family_score += this.q3Form.controls['q19'].value + this.q3Form.controls['q20'].value + this.q3Form.controls['q21'].value +
      this.q3Form.controls['q22'].value;

    data.dom_leadership_score += this.q3Form.controls['q31'].value + this.q3Form.controls['q32'].value + this.q3Form.controls['q33'].value +
      this.q3Form.controls['q34'].value + this.q3Form.controls['q37'].value + this.q3Form.controls['q38'].value + this.q3Form.controls['q39'].value +
      this.q3Form.controls['q40'].value + this.q3Form.controls['q41'].value;

    for (let i = 42; i <= 46; i++) {
      data.dom_work_relations_score += this.q3Form.controls[`q${i}`].value;
    }
    
    if (this.sec2) {
      for (let i = 69; i <= 72; i++) {
        data.dom_work_relations_score += this.q3Form.controls[`q${i}`].value;
      }
    }

    for (let i = 57; i <= 64; i++) {
      data.dom_violence_score += this.q3Form.controls[`q${i}`].value;
    }

    for (let i = 47; i <= 52; i++) {
      data.dom_recognition_score += this.q3Form.controls[`q${i}`].value;
    }

    data.dom_instability_score += this.q3Form.controls['q53'].value + this.q3Form.controls['q54'].value + this.q3Form.controls['q55'].value +
      this.q3Form.controls['q56'].value;

    /* Categories */

    data.cat_work_ambient_score = data.dom_work_conditions_score;
    data.cat_activity_factors_score = data.dom_work_load_score + data.dom_missing_control_score;
    data.cat_work_time_level_score = data.dom_work_hours_score + data.dom_family_score;
    data.cat_work_relations_score = data.dom_leadership_score + data.dom_work_relations_score + data.dom_violence_score;
    data.cat_organization_score = data.dom_recognition_score + data.dom_instability_score;

    /* Final risk */
    data.final_risk_score = data.cat_work_ambient_score + data.cat_activity_factors_score + data.cat_work_time_level_score + data.cat_work_relations_score
      + data.cat_organization_score;

    /* Final risk */

    if (data.final_risk_score < 50) {
      data.final_risk_level_id = 1
    } else if (data.final_risk_score < 75) {
      data.final_risk_level_id = 2
    } else if (data.final_risk_score < 99) {
      data.final_risk_level_id = 3
    } else if (data.final_risk_score < 140) {
      data.final_risk_level_id = 4
    } else {
      data.final_risk_level_id = 5
    }

    /* Categories */

    if (data.cat_work_ambient_score < 5) {
      data.cat_work_ambient_level_id = 1
    } else if (data.cat_work_ambient_score < 9) {
      data.cat_work_ambient_level_id = 2
    } else if (data.cat_work_ambient_score < 11) {
      data.cat_work_ambient_level_id = 3
    } else if (data.cat_work_ambient_score < 14) {
      data.cat_work_ambient_level_id = 4
    } else {
      data.cat_work_ambient_level_id = 5
    }

    if (data.cat_activity_factors_score < 15) {
      data.cat_activity_factors_level_id = 1
    } else if (data.cat_activity_factors_score < 30) {
      data.cat_activity_factors_level_id = 2
    } else if (data.cat_activity_factors_score < 45) {
      data.cat_activity_factors_level_id = 3
    } else if (data.cat_activity_factors_score < 60) {
      data.cat_activity_factors_level_id = 4
    } else {
      data.cat_activity_factors_level_id = 5
    }

    if (data.cat_work_time_level_score < 5) {
      data.cat_work_time_level_id = 1
    } else if (data.cat_work_time_level_score < 7) {
      data.cat_work_time_level_id = 2
    } else if (data.cat_work_time_level_score < 10) {
      data.cat_work_time_level_id = 3
    } else if (data.cat_work_time_level_score < 13) {
      data.cat_work_time_level_id = 4
    } else {
      data.cat_work_time_level_id = 5
    }

    if (data.cat_work_relations_score < 14) {
      data.cat_work_relations_level_id = 1
    } else if (data.cat_work_relations_score < 29) {
      data.cat_work_relations_level_id = 2
    } else if (data.cat_work_relations_score < 42) {
      data.cat_work_relations_level_id = 3
    } else if (data.cat_work_relations_score < 58) {
      data.cat_work_relations_level_id = 4
    } else {
      data.cat_work_relations_level_id = 5
    }

    if (data.cat_organization_score < 10) {
      data.cat_organization_level_id = 1
    } else if (data.cat_organization_score < 14) {
      data.cat_organization_level_id = 2
    } else if (data.cat_organization_score < 18) {
      data.cat_organization_level_id = 3
    } else if (data.cat_organization_score < 23) {
      data.cat_organization_level_id = 4
    } else {
      data.cat_organization_level_id = 5
    }

    /* Domains */

    if (data.dom_work_conditions_score < 5) {
      data.dom_work_conditions_level_id = 1
    } else if (data.dom_work_conditions_score < 9) {
      data.dom_work_conditions_level_id = 2
    } else if (data.dom_work_conditions_score < 11) {
      data.dom_work_conditions_level_id = 3
    } else if (data.dom_work_conditions_score < 14) {
      data.dom_work_conditions_level_id = 4
    } else {
      data.dom_work_conditions_level_id = 5
    }

    if (data.dom_work_load_score < 15) {
      data.dom_work_load_level_id = 1
    } else if (data.dom_work_load_score < 21) {
      data.dom_work_load_level_id = 2
    } else if (data.dom_work_load_score < 27) {
      data.dom_work_load_level_id = 3
    } else if (data.dom_work_load_score < 37) {
      data.dom_work_load_level_id = 4
    } else {
      data.dom_work_load_level_id = 5
    }

    if (data.dom_missing_control_score < 11) {
      data.dom_missing_control_level_id = 1
    } else if (data.dom_missing_control_score < 16) {
      data.dom_missing_control_level_id = 2
    } else if (data.dom_missing_control_score < 21) {
      data.dom_missing_control_level_id = 3
    } else if (data.dom_missing_control_score < 25) {
      data.dom_missing_control_level_id = 4
    } else {
      data.dom_missing_control_level_id = 5
    }

    if (data.dom_work_hours_score < 1) {
      data.dom_work_hours_level_id = 1
    } else if (data.dom_work_hours_score < 2) {
      data.dom_work_hours_level_id = 2
    } else if (data.dom_work_hours_score < 4) {
      data.dom_work_hours_level_id = 3
    } else if (data.dom_work_hours_score < 6) {
      data.dom_work_hours_level_id = 4
    } else {
      data.dom_work_hours_level_id = 5
    }

    if (data.dom_family_score < 4) {
      data.dom_family_level_id = 1
    } else if (data.dom_family_score < 6) {
      data.dom_family_level_id = 2
    } else if (data.dom_family_score < 8) {
      data.dom_family_level_id = 3
    } else if (data.dom_family_score < 10) {
      data.dom_family_level_id = 4
    } else {
      data.dom_family_level_id = 5
    }

    if (data.dom_leadership_score < 9) {
      data.dom_leadership_level_id = 1
    } else if (data.dom_leadership_score < 12) {
      data.dom_leadership_level_id = 2
    } else if (data.dom_leadership_score < 16) {
      data.dom_leadership_level_id = 3
    } else if (data.dom_leadership_score < 20) {
      data.dom_leadership_level_id = 4
    } else {
      data.dom_leadership_level_id = 5
    }

    if (data.dom_work_relations_score < 10) {
      data.dom_work_relations_level_id = 1
    } else if (data.dom_work_relations_score < 13) {
      data.dom_work_relations_level_id = 2
    } else if (data.dom_work_relations_score < 17) {
      data.dom_work_relations_level_id = 3
    } else if (data.dom_work_relations_score < 21) {
      data.dom_work_relations_level_id = 4
    } else {
      data.dom_work_relations_level_id = 5
    }

    if (data.dom_violence_score < 7) {
      data.dom_violence_level_id = 1
    } else if (data.dom_violence_score < 10) {
      data.dom_violence_level_id = 2
    } else if (data.dom_violence_score < 13) {
      data.dom_violence_level_id = 3
    } else if (data.dom_violence_score < 16) {
      data.dom_violence_level_id = 4
    } else {
      data.dom_violence_level_id = 5
    }

    if (data.dom_recognition_score < 6) {
      data.dom_recognition_level_id = 1
    } else if (data.dom_recognition_score < 10) {
      data.dom_recognition_level_id = 2
    } else if (data.dom_recognition_score < 14) {
      data.dom_recognition_level_id = 3
    } else if (data.dom_recognition_score < 18) {
      data.dom_recognition_level_id = 4
    } else {
      data.dom_recognition_level_id = 5
    }

    if (data.dom_instability_score < 4) {
      data.dom_instability_level_id = 1
    } else if (data.dom_instability_score < 6) {
      data.dom_instability_level_id = 2
    } else if (data.dom_instability_score < 8) {
      data.dom_instability_level_id = 3
    } else if (data.dom_instability_score < 10) {
      data.dom_instability_level_id = 4
    } else {
      data.dom_instability_level_id = 5
    }

    console.log(data)
    return data;
  }

  async sendAnswers() {
    this.loadingSend = true;
    let answers = this.getAnswers()

    if (this.questionnaire_id === 5) {
      const answersQ1 = this.answerService.getAnswerDataQ1()
      const mergedAnswers = { ...JSON.parse(answers.answers)}
      const ats_answers = {...JSON.parse(answersQ1.answers)}
      delete answers.answers
      delete answersQ1.answers
      const additionalData =  {...answers, ...answersQ1}
      answers = {
        answers : JSON.stringify(mergedAnswers),
        ats_answers: JSON.stringify(ats_answers),
        ...additionalData
      }
    }
    const request$ = this.questionnaire_id === 5 ? this.answerService.sendAnswers5(answers) : this.answerService.sendAnswers3(answers);

    request$.subscribe( (response) => {
      this.loadingSend = false;
      window.scroll(0, 0);
      if (response.success) {
        this.notifierService.notify('success', 'Respuestas envíadas');
        this.router.navigate(['/questionnaire/data']);
      } else {
        this.errorModalData = {...response.error, title: response.error.code === TIMEOUT_ERROR_CODE ? TIMEOUT_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });


    
  }

}

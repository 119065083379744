import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, retry, timeout, timeoutWith } from 'rxjs/operators';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, FAILURE_RESPONSE_MESSAGE, HTTP_ERROR_RESPONSE, REQUEST_TIMEOUT, RETRY_COUNT, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from '../constants/http-requests.const';
import { HttpDefaultResponse } from '../interfaces/http-default-response';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  isManual: boolean = false;
  answerDataQ1: any = {};
  public clientDataListener: Subject<any> = new Subject()
  public loadingClientDataListener: Subject<any> = new Subject();

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cnq35')
      })
    };
  }

  getBasicAuthHeader(){
    return {
      headers: new HttpHeaders({
        'Authorization': "Basic Y3VlbnRhbWU6UnNla054Z3FNVHRteUJIQmtxZmp3enFTTXJudVBkRWU="
      })
    }
  }

  triggerClientChange(client: any) {
    this.clientDataListener.next(client);
  }

  getData() {
    return this.http.get(`${apiUrl}/answer/data`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  validateAccess(data) {
    return this.http.post(`${apiUrl}/answer/access`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  validateUser(data) {
    return this.http.post(`${apiUrl}/answer/user`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  sendAnswers(data): Observable<HttpDefaultResponse> {
    return this.http.post<HttpDefaultResponse>(`${apiUrl}/answer/q1`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendAnswers2(data): Observable<HttpDefaultResponse> {
    return this.http.post<HttpDefaultResponse>(`${apiUrl}/answer/q2`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendAnswers3(data): Observable<HttpDefaultResponse> {
    return this.http.post<HttpDefaultResponse>(`${apiUrl}/answer/q3`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendAnswers4(data): Observable<HttpDefaultResponse>{
    return this.http.post<HttpDefaultResponse>(`${apiUrl}/answer/q4`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendAnswers5(data: any): Observable<HttpDefaultResponse> {
    return this.http.post<HttpDefaultResponse>(`${apiUrl}/answer/q5`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        console.log('sendAnswers5 catchError!!!!', error);
        return of(this.handleErrorResponse(error))
      })
    )
  }

  // codigo 457 (3 y 5), 442 (2 y 4), 433 (q1) 
  handleErrorResponse(response){
    let error = { 
      success: false,
      error: {
        code: DEFAULT_ERROR_CODE, 
        message: DEFAULT_ERROR_MESSAGE
      } 
    } 
    if (response.error) {
      if (response.error.error) {
        response = response.error;
      }
    }
    console.log('handleErrorResponse', response);
    if (response.error) {
      const errorCode = response.error.code
      if( errorCode === 457 || errorCode === 442 || errorCode === 433 ) {
        return { success: true, error: {code: errorCode, message: null}, data: null }
      }
      error = { success: false, error: {code: response.error.code, message: response.error.message || DEFAULT_ERROR_MESSAGE} }
    } else if (response.code) {
      error = {
        success: false,
        error: {
          code: response.code,
          message: response.message || DEFAULT_ERROR_MESSAGE
        }
      }
    }
    if (response.message ) {
      const message: string = response.message;
      if( message.includes(FAILURE_RESPONSE_MESSAGE)) {
        return { success: false, error: {code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE} }
      } else {
        return { success: false, error: {code: DEFAULT_ERROR_CODE, message: DEFAULT_ERROR_MESSAGE} }
      }
    }
    
    return error;
  }

  sendSocioDemographicData(data): Observable<HttpDefaultResponse> {
    return this.http.patch<HttpDefaultResponse>(`${apiUrl}/answer/employee`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendSocioDemographicData2(data): Observable<HttpDefaultResponse> {
    return this.http.patch<HttpDefaultResponse>(`${apiUrl}/answer/employee/q23`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  sendSocioDemographicData3(data): Observable<HttpDefaultResponse> {
    return this.http.patch<HttpDefaultResponse>(`${apiUrl}/answer/employee/q45`, data, this.getAuthHeader()).pipe(
      timeoutWith(
        REQUEST_TIMEOUT,
        throwError({ code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE })
      ),
      retry(RETRY_COUNT),
      catchError(error => {
        return of(this.handleErrorResponse(error))
      })
    )
  }

  getApplicationUrl(applicationUrl){
    return this.http.get(`${apiUrl}/answer/url/${applicationUrl}`,this.getBasicAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  sendEmployee(data){
    return this.http.post(`${apiUrl}/answer/employee/name`, data, this.getBasicAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getToken(data){
    return this.http.post(`${apiUrl}/answer/token`, data, this.getBasicAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  setAnswerDataQ1(data){
    this.answerDataQ1 = data;
  }

  getAnswerDataQ1(){
    return {...this.answerDataQ1}
  }

  SetIsManual(set){
    this.isManual = set;
  }

  IsManual(){
    return this.isManual;
  }

}

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { AnswerService } from 'src/app/services/answer.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE } from 'src/app/constants/alert-messages.const';

@Component({
  selector: 'app-validate-credentiales',
  templateUrl: './validate-credentiales.component.html'
})
export class ValidateCredentialesComponent implements OnInit {

  employee: any;
  questionnaire: any;
  colors: any;
  notQ1 = false;
  stepQ = 0;
  credentialsForm: FormGroup;
  @ViewChild('modalTerms') public modalTerms: ModalDirective;
  loadingNext = false;
  showForm = false;
  terms = false;
  terms2 = false;
  qType = 0;
  termsExtra1 = false;
  termsExtra2 = false;
  loadingReady = true;
  isManual = false;
  answered = 0;
  userMsg = 'Ingresa tu usuario';
  accessMsg = 'Ingresa tu clave de acceso';
  showSpinner: boolean = false;
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  } 
  @ViewChild('modalError') public modalError: ModalDirective;
  @ViewChild('modalConectionError') public modalConectionError: ModalDirective;

  constructor(private answerService: AnswerService, private router: Router, private notifierService: NotifierService, private onlineStatusService: OnlineStatusService) {
    this.colors = JSON.parse(sessionStorage.getItem('text_colors'));
    this.credentialsForm = new FormGroup({
      user: new FormControl(''),
      access: new FormControl('')
    });
  }

  ngOnInit() {
    this.showSpinner = true;
    this.getData();
    this.modalError.config = {ignoreBackdropClick: true};
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    $event.returnValue = false;
  }

  async getData() {
    this.loadingReady = true;
    const res = await this.answerService.getData();
    this.isManual = this.answerService.IsManual();
    this.loadingReady = false;
    if(!res) {
      const onHide = () => {
        this.router.navigate(['/questionnaire/'], { queryParams: { 'load-error': true } });
      }
      this.showErrorModal(RESTART_FROM_ORIGINAL_URL_MESSAGE, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE, onHide)
      return
    }
    if (res.success) {
      this.answerService.clientDataListener.next(res.data.client);
      this.questionnaire = res.data.questionnaire_application;
      this.employee = res.data.employee;
      this.answered = res.data.answered;
      this.notQ1 = res.data.questionnaire_application.questionnaire_id !== 1 ;
      this.qType = res.data.questionnaire_application.questionnaire_id;
      this.userMsg = res.data.questionnaire_application.user_message ? res.data.questionnaire_application.user_message : 'Ingresa tu usuario';
      this.accessMsg = res.data.questionnaire_application.access_message ? res.data.questionnaire_application.access_message : 'Ingresa tu clave de acceso';
      this.showForm = this.questionnaire.validate_user === 1 || this.questionnaire.validate_access === 1;
    } else {
      this.router.navigate(['/questionnaire/']);
    }
    this.showSpinner = false;
  }

  async validateAccess() {

    this.loadingNext = true;
    const data = {
      access: this.credentialsForm.controls['access'].value
    }
    
    const res = await this.answerService.validateAccess(data);
    this.loadingNext = false;

    if (res.success) {
      if (this.notQ1) {
        this.startQ();
      } else {
        this.router.navigate(['/questionnaire/data']);
      }
    } else {
      this.notifierService.notify('error', 'El accesso no es correcto, intente de nuevo');
    }
  }

  startQ() {
    switch (this.qType) {
      case 1:
        this.router.navigate(['questionnaire/q1']);
        break;
      case 2:
        if (this.answered === 0) {
          this.router.navigate(['questionnaire/q2']);
        } else {
          this.stepQ = 1;
        }
        break;
      case 3:
        if (this.answered === 0) {
          this.router.navigate(['questionnaire/q3']);
        } else {
          this.stepQ = 1;
        }
        break;
      case 4:
        if (this.answered === 0) {
          this.router.navigate(['questionnaire/q1']);
        } else {
          this.stepQ = 1;
        }
        break;
      case 5:
        if (this.answered === 0) {
          this.router.navigate(['questionnaire/q1']);
        } else {
          this.stepQ = 1;
        }
        break;
    }
  }

  showErrorModal (title: string, code: number, message: string, onHide = null) {
    this.errorModalData = {title: title, code: code, message: message};
    const modal = this.modalConectionError
    modal.config = {ignoreBackdropClick: true};
    if(onHide !== null) {
      modal.onHidden.subscribe(onHide)
    }
    setTimeout(() => {
      modal.show()
    }, 100)
  }

  nextQAnswered() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(response.isAlive) {
        this.router.navigate(['questionnaire/data']);
      } else {
        this.showErrorModal(TIMEOUT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE);
      }
    });
  }

  showModalTerms() {
    this.modalTerms.show();
  }

  async validateUser(type) {
    this.loadingNext = true;
    const data = {
      user: this.credentialsForm.controls['user'].value
    }
    const res = await this.answerService.validateUser(data);
    this.loadingNext = false;
    if (res.success) {
      if (type === 1) {
        if (this.notQ1) {
          this.startQ();
        } else {
          this.router.navigate(['/questionnaire/data']);
        }
      } else {
        this.validateAccess();
      }
    } else {
      this.notifierService.notify('error', 'El usuario no es correcto, intente de nuevo');
    }
  }

  showTerms() {
    this.modalTerms.show();
  }


  goNext() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(response.isAlive) {
        this.startQuestionnaire();
      } else {
        this.showErrorModal(TIMEOUT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE);
      }
    });
  }

  startQuestionnaire() {
    console.log('qType', this.qType)
    console.log('notQ1', this.notQ1)
    if(!this.terms || !this.terms2 || (this.notQ1 && (!this.termsExtra1 || !this.termsExtra2))) {
      this.modalError.show();
      return;
    }
    if (this.questionnaire.validate_user === 1 && this.questionnaire.validate_access === 1) {
      this.validateUser(2);
    } else if (this.questionnaire.validate_user === 1 || this.questionnaire.validate_access === 1) {
      if (this.questionnaire.validate_user === 1) {
        this.validateUser(1);
      } else {
        this.validateAccess();
      }
    } else {
      if (this.notQ1) {
        this.startQ();
      } else {
        this.router.navigate(['/questionnaire/data']);
      }
    }
  }

}
